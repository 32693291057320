import axios, { AxiosPromise } from 'axios';

const BASE_URL = '/console-api/api/cache';

export const clearRealmCache = (): AxiosPromise =>
  axios.post(`${BASE_URL}/clear-realm-cache`);

export const clearUserCache = (): AxiosPromise =>
  axios.post(`${BASE_URL}/clear-user-cache`);

export const clearConfigsCache = (): AxiosPromise<void> =>
  axios.post(`${BASE_URL}/clear-cache`);
