import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { PermissionToggle } from '../type/PermissionToggle';
import { PermissionToggleStats } from '../type/PermissionToggleStats';

export const findAll = (
  modulo: string,
  tenant: string,
  filter: string,
  search: string = '',
  page?: PageRequest,
  sort?: Sort,
  entidade?: string
): AxiosPromise<PagedResponse<PermissionToggle>> => {
  return axios.get(
    `/console-api/api/client-permission-toggle/${tenant}/${modulo}`,
    {
      params: {
        filter,
        search,
        entidade,
        ...page,
        ...sort
      }
    }
  );
};

export const save = (
  body: { feature: string; active: boolean }[],
  tenant: string,
  entidade: string
): AxiosPromise<void> => {
  return axios.post(
    `/console-api/api/client-permission-toggle/${tenant}`,
    body,
    {
      params: {
        entidade
      }
    }
  );
};

export const getStats = (
  modulo: string,
  tenant: string,
  entidade: string
): AxiosPromise<PermissionToggleStats> => {
  return axios.get(
    `/console-api/api/client-permission-toggle/stats/${tenant}/${modulo}`,
    {
      params: {
        entidade
      }
    }
  );
};
