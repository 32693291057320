import './FilterCards.css';

import React from 'react';

export type FilterCardType<T extends string> = {
  type: T;
  title: string;
  count: number;
};

export type FilterCardsProps<T extends string> = {
  cards: FilterCardType<T>[];
  selectedFilter: T;
  onFilterChange: (type: T) => void;
  getClassNameCard: (type: string) => string;
};

const FilterCards = <T extends string>({
  cards,
  onFilterChange,
  getClassNameCard
}: FilterCardsProps<T>): React.ReactElement => {
  return (
    <div className="row scroll mb-xs filter-block">
      {cards.map(card => (
        <div
          key={card.type}
          className="col-md-2 filter-card"
          onClick={() => onFilterChange(card.type)}
        >
          <div className={getClassNameCard(card.type)}>
            <div className="row">
              <div className="col-md-12 feature-title">
                {card.title}
                <i className={'fa fa-filter'}></i>
              </div>
              <div className="col-md-12 feature-flag-number">{card.count}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterCards;
