import { Alert, Container } from '@elotech/components';
import React, { useState } from 'react';

import { CacheService } from '../../service';

type Props = {};

const CachePage: React.FC<Props> = () => {
  const [clearCache, setClearCache] = useState(false);
  const [clearingUserCache, setClearingUserCache] = useState(false);
  const [clearingRealmCache, setClearingRealmCache] = useState(false);

  const clearConfigCache = () => {
    setClearCache(true);
    CacheService.clearConfigsCache()
      .then(() => {
        Alert.success({
          title: 'Cache da configuração limpa com sucesso.'
        });
      })
      .catch(error => {
        Alert.error({ title: `Erro ao limpar cache da configuração` }, error);
      })
      .finally(() => setClearCache(false));
  };

  const clearUserCache = () => {
    setClearingUserCache(true);
    CacheService.clearUserCache()
      .then(() => {
        Alert.success({
          title: 'Cache do usuário limpo com sucesso.'
        });
      })
      .catch(error => {
        Alert.error({ title: `Erro ao limpar cache do usuário` }, error);
      })
      .finally(() => setClearingUserCache(false));
  };

  const clearRealmCache = () => {
    setClearingRealmCache(true);
    CacheService.clearRealmCache()
      .then(() => {
        Alert.success({
          title: 'Cache do realm limpo com sucesso.'
        });
      })
      .catch(error => {
        Alert.error({ title: `Erro ao limpar cache do realm` }, error);
      })
      .finally(() => setClearingRealmCache(false));
  };

  return (
    <Container breadcrumb>
      <button
        className="btn outlined module-color icon-left inline"
        onClick={clearConfigCache}
      >
        <i className={`fa fa-sync-alt ${clearCache ? 'fa-spin' : ''}`} />
        Limpar cache das configurações
      </button>
      <button
        className="btn outlined module-color icon-left inline"
        onClick={clearUserCache}
      >
        <i className={`fa fa-sync-alt ${clearingUserCache ? 'fa-spin' : ''}`} />
        Limpar cache usuários
      </button>
      <button
        className="btn outlined module-color icon-left inline"
        onClick={clearRealmCache}
      >
        <i
          className={`fa fa-sync-alt ${clearingRealmCache ? 'fa-spin' : ''}`}
        />
        Limpar cache do Realm
      </button>
    </Container>
  );
};

export default CachePage;
