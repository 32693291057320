import {
  Alert,
  AutoComplete,
  Col,
  Container,
  FAB,
  Loading,
  PagedResponse,
  Panel,
  ProtectedComponent,
  Row,
  SearchPagination,
  Table
} from '@elotech/components';
import {
  PageRequest,
  PaginationData,
  Sort
} from '@elotech/components/dist/types/type';
import { selectUnit } from '@formatjs/intl-utils';
import { FilterCards } from 'common/components';
import { FilterCardType } from 'common/components/filter-cards/FilterCards';
import BasicSearchFilter from 'common/components/filters/BasicSearchFilter';
import { ClienteService, ModuloService } from 'common/service';
import { Cliente, Entidade, ModuloDTO, initEntidade } from 'common/type';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedRelativeTime } from 'react-intl';

import { Roles } from '../../roles/RolesConsole';
import { findAll, getStats, save } from '../../service/PermissionToggleService';
import { PermissionToggleStats } from '../../type/PermissionToggleStats';

type Props = {};

type PermissionToggle = {
  name: string;
  description: string;
  active: boolean;
  lastUpdate: string;
};

type PermissionFilterType = 'ALL_FLAGS' | 'ONLY_ACTIVE' | 'ONLY_INACTIVE';

const PermissionToggleForm: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState<PaginationData>({
    numberOfElements: 0,
    number: 0,
    size: 20,
    totalPages: 0,
    totalElements: 0,
    first: true,
    last: true,
    content: []
  } as PagedResponse<any>);

  const [client, setClient] = useState<Cliente>({} as Cliente);

  const [modulos, setModulos] = useState<ModuloDTO[]>([]);

  const [modulo, setModulo] = useState<string | undefined>(undefined);

  const [entidade, setEntidade] = useState<Entidade>(initEntidade);

  const [search, setSearch] = useState<string>('');

  const [changedFlags, setChangedFlags] = useState<
    { feature: string; active: boolean }[]
  >([]);

  const [defaultFilters, setDefaultFilters] = useState<
    'ALL_FLAGS' | 'ONLY_INACTIVE' | 'ONLY_ACTIVE'
  >('ALL_FLAGS');

  const [features, setFeatures] = useState<PermissionToggle[]>([]);

  const [stats, setStats] = useState<PermissionToggleStats>({
    total: 0,
    totalActive: 0,
    totalInactive: 0
  });

  const defineEntidade = (id: string) => {
    setEntidade(
      client.entidades.find(entidade => entidade.id === id) || initEntidade
    );
  };

  const saveFlags = () => {
    if (changedFlags.length !== 0) {
      setLoading(true);

      save(changedFlags, client.tenant, entidade.id)
        .then(() => {
          Alert.success({ title: 'Alterações salvas com sucesso' });
          setChangedFlags([]);
        })
        .then(() => fetchData(defaultFilters, search))
        .catch(error =>
          Alert.error({ title: 'Erro ao salvar alterações' }, error)
        )
        .finally(() => setLoading(false));
    }
  };

  const defineClient = (client: Cliente) => {
    setClient(client ?? {});
    setEntidade(initEntidade);
    defineModulos(client.tenant);
    setFeatures([]);
    setStats({ total: 0, totalActive: 0, totalInactive: 0 });
  };

  const defineModulos = (tenant: string) => {
    setLoading(true);

    ModuloService.getModulosAtivos(tenant)
      .then(response => {
        if (response.data.length > 0) {
          setModulos(response.data);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchData = useCallback(
    async (
      defaultFilter: 'ALL_FLAGS' | 'ONLY_ACTIVE' | 'ONLY_INACTIVE',
      search: string
    ) => {
      if (!modulo) return;

      setLoading(true);

      try {
        const [featuresResponse, statsResponse] = await Promise.all([
          findAll(
            modulo,
            client.tenant,
            defaultFilter,
            search,
            { page: pagination.number, size: pagination.size },
            { sort: '' },
            entidade?.id
          ),
          getStats(modulo, client.tenant, entidade?.id)
        ]);

        refreshPage(featuresResponse.data.content);

        setPagination(prev => ({
          ...prev,
          ...featuresResponse.data,
          number: featuresResponse.data.number
        }));

        setStats(statsResponse.data);
      } catch (error) {
        Alert.error({ title: 'Erro ao buscar funcionalidades' }, error);
      } finally {
        setLoading(false);
      }
    },
    [modulo, entidade?.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      fetchData(defaultFilters, search);
    },
    [fetchData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => doPagedSearch({ page: 0, size: pagination.size }, defaultFilters),
    [search] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const doPagedSearch = (
    page: PageRequest,
    defaultFilter: 'ALL_FLAGS' | 'ONLY_ACTIVE' | 'ONLY_INACTIVE',
    sort?: Sort | undefined
  ) => {
    if (modulo) {
      setLoading(true);

      findAll(
        modulo,
        client.tenant,
        defaultFilter,
        search ?? '',
        page,
        sort,
        entidade?.id
      )
        .then((response: any) => {
          refreshPage(response.data.content);
          setPagination(response.data);
        })
        .finally(() => setLoading(false));
    }
  };

  const onSearch = (search?: string) => {
    setSearch(search ? `name==*${search}*,description==*${search}*` : '');
  };

  const refreshPage = (features: PermissionToggle[]) => {
    const featuresTreated = features.map(f => {
      const changed = changedFlags.find(c => c.feature === f.name);
      if (changed) {
        f.active = changed.active;
      }
      return f;
    });

    setFeatures(featuresTreated);
  };

  const setFeature = (feature: PermissionToggle) => {
    const index = features.findIndex(f => f.name === feature.name);

    features[index] = feature;

    setFeatures([...features]);

    const changed = changedFlags.filter(f => f.feature !== feature.name);

    setChangedFlags([
      ...changed,
      { feature: feature.name, active: feature.active }
    ]);
  };

  const switchFilter = (
    type: 'ALL_FLAGS' | 'ONLY_INACTIVE' | 'ONLY_ACTIVE'
  ) => {
    setDefaultFilters(type);
    setChangedFlags([]);
    doPagedSearch({ page: 0, size: pagination.size }, type);
  };

  const getClassNameCard = (type: string) => {
    return `card pointer ${defaultFilters === type ? 'active' : ''}`;
  };

  const filterCards: FilterCardType<PermissionFilterType>[] = [
    {
      type: 'ALL_FLAGS',
      title: 'Todas as funcionalidades',
      count: stats.total
    },
    {
      type: 'ONLY_ACTIVE',
      title: 'Somente ativas',
      count: stats.totalActive
    },
    {
      type: 'ONLY_INACTIVE',
      title: 'Somente desativadas',
      count: stats.totalInactive
    }
  ];

  return (
    <Container breadcrumb>
      <Loading loading={loading} />

      <Panel>
        <Row className="row-sector mb-xs container-fluid">
          <Col md={4}>
            <label className="label"> Cliente </label>
            <AutoComplete<Cliente>
              value={client}
              getOptionLabel={(value: any) =>
                value.nome ? `${value?.nome} - ${value?.tenant}` : ''
              }
              name={'client'}
              onSearch={(search: string) => {
                return ClienteService.search(search, {
                  page: 0,
                  size: 15
                });
              }}
              onSelect={(_, client: any) => defineClient(client)}
            />
          </Col>
        </Row>

        {client.id && (
          <Row className="row-sector container-fluid">
            <Col md={4}>
              {
                <>
                  <label className="label"> Módulos </label>
                  <select
                    value={modulo}
                    onChange={e => setModulo(e.target.value)}
                  >
                    <option defaultValue={undefined}>
                      Seleciona um módulo
                    </option>

                    {modulos.map(modulo => (
                      <option key={modulo.nome} value={modulo.nome}>
                        {modulo.descricao}
                      </option>
                    ))}
                  </select>
                </>
              }
            </Col>

            <Col md={3}>
              <label className="label"> Entidade </label>
              <>
                <select
                  value={entidade.id}
                  onChange={e => defineEntidade(e.target.value)}
                >
                  <option defaultValue={undefined}>Todas</option>
                  {client.entidades.map(entidade => (
                    <option key={entidade.id} value={entidade.id}>
                      {entidade.nome}
                    </option>
                  ))}
                </select>
              </>
            </Col>
          </Row>
        )}

        <div>
          {modulo && (
            <>
              <FilterCards<PermissionFilterType>
                cards={filterCards}
                selectedFilter={defaultFilters}
                onFilterChange={switchFilter}
                getClassNameCard={getClassNameCard}
              />
              <div className={'container-fluid mt-xs'}>
                <Panel isTable>
                  <BasicSearchFilter
                    field={{
                      label: '',
                      name: '',
                      type: 'STRING'
                    }}
                    onSearch={onSearch}
                    textButton={'Pesquisar'}
                  />

                  <Table values={features}>
                    <Table.Column<PermissionToggle>
                      header={'Ativo'}
                      value={permission => (
                        <div
                          className="switch-container"
                          onClick={() => {
                            permission.active = !permission.active;
                            setFeature(permission);
                          }}
                        >
                          <em
                            className={`switch module-color ${
                              permission.active ? 'active' : ''
                            }`}
                          ></em>
                        </div>
                      )}
                    />

                    <Table.Column<PermissionToggle>
                      header="Nome"
                      value={permission => permission.name}
                    />

                    <Table.Column<PermissionToggle>
                      header="Descrição"
                      value={permission => permission.description}
                    />

                    <Table.Column<PermissionToggle>
                      sortable
                      header="Última atualização"
                      value={permission => {
                        const { value, unit } = selectUnit(
                          new Date(permission.lastUpdate)
                        );

                        return (
                          <>
                            <FormattedRelativeTime value={value} unit={unit} />
                          </>
                        );
                      }}
                    />
                  </Table>

                  {pagination && (
                    <SearchPagination
                      page={pagination}
                      searchWithPage={(
                        page: PageRequest,
                        sort: Sort | undefined
                      ) => doPagedSearch(page, defaultFilters, sort)}
                    />
                  )}
                </Panel>
              </div>
            </>
          )}
        </div>
      </Panel>

      <ProtectedComponent role={Roles.permission_toggle_write.name}>
        <div className="btn-save">
          <FAB
            data-test-id="btn-save"
            icon="check"
            onClick={saveFlags}
            title="Salvar"
          />
        </div>
      </ProtectedComponent>
    </Container>
  );
};

export default PermissionToggleForm;
